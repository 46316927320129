import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18n from './component/context/translation';
import { LanguageProvider } from './component/context/Language';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <LanguageProvider>
<I18nextProvider i18n={i18n}>
  <App />
</I18nextProvider>
  </LanguageProvider>
  ,
);


reportWebVitals();
