import { AxiosResponse } from "axios";
import { deleteAsync, getAsync, postAsync } from "./client";

class VocabService {
  async get(params: any): Promise<AxiosResponse> {
    try {
      const response = await getAsync('/vocab', {
        ...params,
        isPublish: true
      });
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error; 
    }
  }

  async getByUserId(userId: string): Promise<AxiosResponse> {
    try {
      const response = await getAsync(`/vocab/user/${userId}`);
      return response;
    } catch (error) {
      console.error(`Error fetching vocab with ID ${userId}:`, error);
      throw error; 
    }
  }
  async getById(id: string): Promise<AxiosResponse> {
    try {
      const response = await getAsync(`/vocab/${id}`);
      return response;
    } catch (error) {
      console.error(`Error fetching vocab with ID ${id}:`, error);
      throw error; 
    }
  }
  async getVocabQuiz(userId: string): Promise<AxiosResponse> {
    try {
      const response = await getAsync(`/vocab/quiz/${userId}`);
      return response;
    } catch (error) {
      console.error(`Error fetching vocab with ID ${userId}:`, error);
      throw error; 
    }
  }

  async post(formData: FormData): Promise<AxiosResponse> {
    try {
      const response = await postAsync('/vocab', formData);
      return response;
    } catch (error) {
      console.error("Error posting vocab data:", error);
      throw error; 
    }
  }
  async delete(_id: string): Promise<AxiosResponse>  {
    return await deleteAsync(`/vocab/${_id}`);
  }
}

// Create an instance of VocabService
const vocabServiceInstance = new VocabService();

export default vocabServiceInstance;
