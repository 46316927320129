import { Calendar, Modal } from "antd";
import React, { useState } from "react";
import dayjs from "dayjs";
import TitleComponent from "../../global/Custom/TitleComponent/TitleComponent";
import { useTranslation } from "react-i18next";

function CalendarCustom() {
  const {t} = useTranslation()
  const [value, setValue] = useState(() => dayjs());
  const [selectedValue, setSelectedValue] = useState(() => dayjs());
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onSelect = (newValue: any) => {
    setValue(newValue);
    setSelectedValue(newValue);

    // Show modal on selecting any date
    setIsModalVisible(true);
  };

  const onPanelChange = (newValue: any) => {
    setValue(newValue);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
        <TitleComponent
            title={t("wordform")}
            type="primary"
            data={t("add")}
            to="/Grammar/Wordform/create"
          />
      <Calendar
        value={value}
        onSelect={onSelect}
        onPanelChange={onPanelChange}
      />
      <Modal
        title="Selected Date"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>You selected: {selectedValue.format("YYYY-MM-DD")}</p>
      </Modal>
    </div>
  );
}

export default CalendarCustom;
