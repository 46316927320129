import Lottie from "lottie-react";
import animation from "../../../accset/json/wave.json";
import TitleComponent from "../../global/Custom/TitleComponent/TitleComponent";
import { useTranslation } from "react-i18next";
import useSpeechSynthesis from "../../context/speech";
import { useEffect, useState } from "react";
import { dispatch, useSelector } from "../../redux/store";
import { Button, Card, Flex, Input, Popover } from "antd";
import { getVocabQuiz } from "../../redux/action/vocab";
import { openNotification } from "../../global/item/Notification/Notification";
import { CheckOutlined, BookOutlined } from '@ant-design/icons';

const levenshtein = (a: string, b: string): number => {
  const alen = a.length;
  const blen = b.length;
  const dp: number[][] = [];
  let tmp: number;

  if (alen === 0) {
    return blen;
  }
  if (blen === 0) {
    return alen;
  }

  for (let i = 0; i <= alen; i++) {
    dp[i] = [i];
  }
  for (let j = 0; j <= blen; j++) {
    dp[0][j] = j;
  }

  for (let i = 1; i <= alen; i++) {
    for (let j = 1; j <= blen; j++) {
      tmp = a.charAt(i - 1) === b.charAt(j - 1) ? 0 : 1;
      dp[i][j] = Math.min(
        dp[i - 1][j] + 1, // Deletion
        dp[i][j - 1] + 1, // Insertion
        dp[i - 1][j - 1] + tmp // Substitution
      );
    }
  }

  return dp[alen][blen];
};


const similarityPercentage = (a: string, b: string): number => {
  const maxLen = Math.max(a.length, b.length);
  const distance = levenshtein(a, b);
  return ((maxLen - distance) / maxLen) * 100;
};


function ListenTest() {
  const { t } = useTranslation();
  const { speakText } = useSpeechSynthesis();
  const userId = localStorage.getItem("id");
  const { vocabList } = useSelector((state) => state.vocab);

  const [examples, setExamples] = useState<string[]>([]);
  const [currentExample, setCurrentExample] = useState<string>("");
  const [userInput, setUserInput] = useState<string>("");
  const [showScript, setShowScript] = useState(false);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
  const [similarity, setSimilarity] = useState<number>(0);
  console.log('====================================');
  console.log("similarity",similarity);
  console.log('====================================');
  useEffect(() => {
    dispatch(getVocabQuiz(userId));
  }, [userId]);

  useEffect(() => {
    if (vocabList && vocabList.length > 0) {
      const extractedExamples = vocabList
        .filter((vocab) => vocab.example)
        .map((vocab) => vocab.example);
      setExamples(extractedExamples);
      selectRandomExample(extractedExamples);
    }
  }, [vocabList]);
  const stripHTML = (html: string) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };
  
  const selectRandomExample = (examplesList: string[]) => {
    if (examplesList.length > 0) {
      const randomIdx = Math.floor(Math.random() * examplesList.length);
      setCurrentExample(examplesList[randomIdx]);
    }
  };

  const handleCollapseChange = (text: any) => {
    if ("speechSynthesis" in window) {
      speakText(text);
    } 
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value);
  };

  const handleCheckAnswer = () => {
    const strippedExample = stripHTML(currentExample);
    const strippedUserInput = stripHTML(userInput);
  
    const similarityPercent = similarityPercentage(strippedExample, strippedUserInput);
    setSimilarity(similarityPercent);
  
    if (similarityPercent >= 80) { // Adjust the threshold as needed
      openNotification({
        type: "success",
        message: t("success"),
        description: (
          <div>
          <span style={{ color: 'green' }}>
            {`${similarityPercent.toFixed(0)}% `}
          </span>
          phù hợp. Hãy thử lại.
          </div>
          
        ),
      });
      setUserInput(""); 
      setShowScript(false); 
      setIsAnswerCorrect(true); 
    } else {
      openNotification({
        type: "error",
        message: t("error"),
        description: (
          <div>
          <span style={{ color: 'red' }}>
            {`${similarityPercent.toFixed(0)}% `}
          </span>
          phù hợp. Hãy thử lại.
          </div>
          
        ),
      });
      setIsAnswerCorrect(false); 
    }
  };
  

  const handleShowScript = () => {
    if (currentExample) {
      setShowScript(true);
    }
  };

  const handleNextExample = () => {
    if (isAnswerCorrect) {
      const updatedExamples = examples.filter((example) => example !== currentExample);
      if (updatedExamples.length > 0) {
        selectRandomExample(updatedExamples);
        setExamples(updatedExamples);
      } else {
        alert("Congratulations! You've completed all examples.");
        setCurrentExample("");
      }
      setIsAnswerCorrect(false);
    } else {
      alert("Please answer the current example correctly before moving on.");
    }
  };

  const content = (
    <div>
      <p style={{ padding: "5px 8px", color: `var(--primary-color)` }}>
        Ấn vào để được nghe lại
      </p>
    </div>
  );

  return (
    <div>
      <TitleComponent title={t("Kiểm tra từ vựng")} isExist={false} />
      <Popover content={content}>
        <Lottie
          animationData={animation}
          style={{ width: 350, cursor: "pointer", margin: "0 auto" }}
          onClick={(e: any) => {
            // e.stopPropagation();
            const textToRead = stripHTML(currentExample); // Strip out HTML tags and extract text
            handleCollapseChange(textToRead); 
          }}
        />
      </Popover>

      {showScript && currentExample && (
        <h3
          style={{
            textAlign: "center",
            marginBottom: 15,
            color: `var(--primary-color)`,
          }}
        >
          Script:
          <span style={{ fontWeight: 400, paddingLeft: 4 }} dangerouslySetInnerHTML={{ __html: currentExample }} />
        </h3>
      )}

      <Card>
        <h2 style={{ marginTop: 0 }}>Câu trả lời của bạn</h2>
        <Input
          placeholder="Nhập từ bạn nghe được"
          style={{ height: 80 }}
          value={userInput}
          onChange={handleInputChange}
        />
        <Flex justify="center" gap={20} style={{ marginTop: 20 }}>
          <Button type="primary" onClick={handleCheckAnswer}>
          <CheckOutlined />Kiểm tra
          </Button>
          <Button type="dashed" onClick={handleShowScript}>
          <BookOutlined />Hiện Script
          </Button>
          {isAnswerCorrect && (
            <Button type="primary" onClick={handleNextExample}>
              Tiếp tục
            </Button>
          )}
        </Flex>
      </Card>
    </div>
  );
}

export default ListenTest;
