import React, { useState } from "react";
import { Button, Col, Row, Form, Input, Flex } from "antd";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import Loading from "../../global/item/Loading/loading";

import authService from "../../service/auth";
import UseRespon from "../../../hook/useResponsive";
import signInAnimation from "../../../accset/json/signInJson.json";

import "./SignIn.scss";
import { openNotification } from "../../global/item/Notification/Notification";
import { useNavigate } from "react-router-dom";
import Translation from "../../global/item/Translation/Translation";

function ForgotPass() {
  const { t } = useTranslation();
  const { isMobile } = UseRespon();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    const email = {
      email: values.email,
    };

    try {
      setLoading(true);
      const response = await authService.forgotPassword(email);
      openNotification({
        type: "success",
        message: t("success"),
        description: t("otpSentToEmail"),
      });
      setTimeout(() => {
        const { token } = response.data;
        if (token) {
          localStorage.setItem("token", token);
          navigate("/");
        }
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      openNotification({
        type: "error",
        message: t("error"),
        description: t("errorForgot"),
      });
    }
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div style={{ background: "#f2f2f2" }}>
          <Row style={{ height: "100vh" }}>
            {isMobile ? null : (
              <Col xl={16}>
                 <div
                  style={{
                    height: "50px",
                    borderRadius: "10px",
                    margin: "10px 40px",
                  }}
                >
                  <Flex>
                      <h2
                        style={{
                          color: `var(--primary-color)`,
                          fontFamily: "Playwrite CU, sans-serif",
                        }}
                      >
                        E<span style={{ color: "#000" }}>learning</span>
                      </h2>
                    </Flex>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "calc(100vh - 90px)",
                  }}
                >
                  <Lottie
                    animationData={signInAnimation}
                    style={{ width: 550 }}
                  />
                </div>
              </Col>
            )}
            <Col xl={8} xs={24} className="signInRight">
              <Translation />
              <h2 className="signInRight--title">{t("welcome")}</h2>
              <p
                style={{
                  paddingTop: 10,
                  paddingBottom: 3,
                  color: 'rgba(0, 0, 0, 0.5)',
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                {t("youForgot")}
              </p>
              <span
                style={{
                  paddingBottom: 40,
                  color: 'rgba(9, 2, 2, 0.5)',
                  fontWeight: 400,
                  textAlign: "center",
                }}
              >
                {t("forgotPassDesc")}
              </span>

              <Form
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  className="formItem"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t("emailRequired"),
                    },
                    {
                      type: "email",
                      message: t("emailGmailRequired"),
                    },
                  ]}
                >
                  <div className="form-field">
                    <Input className="form-input" placeholder="" />
                    <label htmlFor="email" className="form-label">
                      {t("email")}
                    </label>
                  </div>
                </Form.Item>

                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    height: 46,
                    marginTop: 20,
                    fontWeight: 600,
                  }}
                >
                  {t("confirm")}
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default ForgotPass;
