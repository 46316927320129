import LayoutApp from "../component/global/Dashboard/Layout";
import ForgotPass from "../component/pages/Account/ForgotPass";
import RegisterApp from "../component/pages/Account/RegisterApp";
import ResetPass from "../component/pages/Account/ResetPassword";
import SignIn from "../component/pages/Account/SignIn";
import Verify from "../component/pages/Account/Verify";
import CalendarCustom from "../component/pages/Calendar/Calendar";
import Dashboard from "../component/pages/Dashboard/Dashboard";
import WordformCretae from "../component/pages/Grammar/Wordform/WordformCreate";
import WordformDetail from "../component/pages/Grammar/Wordform/WordformDetail";
import WordformList from "../component/pages/Grammar/Wordform/WordformList";
import Page404 from "../component/pages/NotFound/page404";
import GrammarAnswer from "../component/pages/Review/GrammarAnswer";
import ListenTest from "../component/pages/Review/ListenTest";
import Review from "../component/pages/Review/Review";
import SelectAnswer from "../component/pages/Review/SelectAnswer";

import TopicCreate from "../component/pages/Topic/TopicCreate";
import TopicDetail from "../component/pages/Topic/TopicDetail";
import TopicList from "../component/pages/Topic/TopicList";
import VocabCreate from "../component/pages/Vocab/VocabCreate";
import VocabList from "../component/pages/Vocab/VocabList";

const routes = [
  {
    path: "/",
    element: <LayoutApp />,
    requiresAuth: true,
    children: [
      {
        path: "Dashboard",
        element: <Dashboard />,
        requiresAuth: true,
      },
      {
        path: "Grammar",

        requiresAuth: true,
      },
      {
        path: "Grammar/Wordform",
        element: <WordformList />,
        requiresAuth: true,
      },
      {
        path: "Grammar/Wordform/create",
        element: <WordformCretae />,
        requiresAuth: true,
      },
      {
        path: "Grammar/Wordform/Detail",
        element: <WordformDetail />,
        requiresAuth: true,
      },
      {
        path: "Topic",
        element: <TopicList/>,
        requiresAuth: true,
      },
      {
        path: "Topic/Create",
        element: <TopicCreate/>,
        requiresAuth: true,
      },
      {
        path: "Topic/Detail",
        element: <TopicDetail />,
        requiresAuth: true,
      },
      {
        path: "Vocab",
        element: <VocabList/>,
        requiresAuth: true,
      },
      {
        path: "Vocab/Create",
        element: <VocabCreate/>,
        requiresAuth: true,
      },
      {
        path: "Schedule",
        element: <CalendarCustom/>,
        requiresAuth: true,
      },
      {
        path: "Review",
        element: <Review/>,
        requiresAuth: true,
      },
      {
        path: "Review/Vocabulary",
        element: <SelectAnswer/>,
        requiresAuth: true,
      },
      {
        path: "Review/GrammarAnswer",
        element: <GrammarAnswer/>,
        requiresAuth: true,
      },
      {
        path: "Review/Listening",
        element: <ListenTest/>,
        requiresAuth: true,
      },
    ],
  },
  {
    path: "/sign-in",
    element: <SignIn />,
    requiresAuth: false,
  },
  {
    path: "/register",
    element: <RegisterApp />,
    requiresAuth: false,
  },
  {
    path: "/forgot-password",
    element: <ForgotPass />,
    requiresAuth: false,
  },
  {
    path: "/reset-password",
    element: <ResetPass />,
    requiresAuth: false,
  },
  {
    path: "/verify",
    element: <Verify />,
    requiresAuth: false,
  },
  {
    path: "*",
    element: <Page404 />,
    requiresAuth: false,
  },
];

export default routes;
