import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SendEmailState } from "../../@type/global";
import globalServiceInstance from "../../service/global";


export const sendEmail = createAsyncThunk(
    "get/sendEmail",
    async (formData: FormData) => {
      const { data } = await globalServiceInstance.post(formData);
      return data;
    }
  );
const initialState: SendEmailState = {
    emailList: [],
 

};

const wordformSlice = createSlice({
  name: "global",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendEmail.fulfilled, (state, action) => {
      state.emailList = action.payload;
    });
  
  },
});

export default wordformSlice.reducer;
