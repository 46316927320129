const ko = {
  "layout": {
    "dashboard": "대시보드",
    "home": {
      "home": "홈",
      "banner": "배너",
    },
    "banner": {
      "subSection1": "서브 섹션 1",
      "subSection2": "서브 섹션 2"
    },
    "success": "성공",
    "updateSuccess": "데이터가 성공적으로 업데이트되었습니다!"
  },
  "home": {

  },
  "edit": "편집",
  "basicInformation": "기본 정보",
  "tag": "태그",
  "title": "제목",
  "describtion": "설명",
  "imageInfo": "이미지 정보",
  "vocab": "어휘",
  "add": "지금 추가",
  "topic": "주제",
  "welcome": "환영합니다",
  "signInAccount": "계정에 로그인하세요",
  "password": "비밀번호",
  "email": "이메일",
  "emailRequired": "이메일을 입력하세요!",
  "emailGmailRequired": "올바른 이메일 형식을 입력하세요!",
  "passwordRequired": "비밀번호를 입력하세요!",
  "forgotPass": "비밀번호를 잊으셨나요",
  "youForgot": "비밀번호를 잊으셨나요?",
  "forgotPassDesc": "걱정 마세요, 비밀번호를 업데이트할 수 있도록 이메일을 입력해주세요.",
  "signIn": "로그인",
  "noAccount": "계정이 아직 없으신가요?",
  "registerNow": "지금 등록",
  "or": "또는",
  "usernameRequired": "사용자 이름을 입력하세요!",
  "username": "이름",
  "signUpAccount": "계정을 등록하세요",
  "useAccount": "이미 계정이 있으신가요?",
  "loginNow": "지금 로그인",
  "signUp": "회원가입",
  "otpSentToEmail": "OTP가 이메일로 전송되었습니다",
  "emailExists": "이메일이 이미 존재합니다",
  "useAnotherEmail": "다른 이메일을 사용하세요",
  "success": "성공",
  "error": "오류",
  "LoginSuccess": "로그인 성공",
  "loginFailure": "로그인 실패",
  "RegisterSuccess": "회원가입 성공",
  "errorForgot": "이메일이 존재하지 않거나 구글 계정을 재설정할 수 없습니다",
  "confirm": "확인",
  "verifyAccount": "계정을 확인하세요",
  "verifyDesc": "이메일에서 받은 인증 코드를 입력하세요",
  "otp": "OTP",
  "otpRequired": "OTP를 입력하세요!",
  "verifySuccess": "인증 성공",
  "verifyFailure": "인증 실패",
  "tokenError": "토큰이 만료되었거나 유효하지 않습니다",
  "grammar": "문법",
  "wordform": "단어 형태",
  basic_information: "기본 정보",
  additional_information: "추가 정보",
  word_type: "단어 유형",
  symbol: "기호",
  enter_word_type_name: "단어 유형 이름을 입력하세요",
  enter_data_name: "데이터 이름을 입력하세요",
  please_input_your_data: "데이터를 입력하세요!",
  image: "이미지",
  please_select_an_image: "이미지를 선택하세요!",
  uploaded_image: "업로드된 이미지",
  upload_text: "이 영역을 클릭하거나 파일을 드래그하여 업로드",
  upload_hint: "단일 업로드를 지원합니다. 파일을 여기에 클릭하거나 드래그하세요.",
  cancel: "취소",
  continue: "계속",
  successCreate: "생성 성공",
  errorCreate: "생성 실패",
  searchItem: "검색어...",
  review: "리뷰",
  transcrip: "전사",
  meaning: "의미",
  synonymo: "동의어",
  antonym: "반의어",
  phrase: "구문",
  context: "문맥",
  example: "예시",
  note: "노트",
  logout: "로그아웃",
  profile: "윤곽",
  schedule: "수업 일정을 정하다",
  history: "역사",
  needHelp: "도움이 필요하신가요? 저에게 연락하세요",
  contact: "나에게 연락",
  problem: "당신의 문제",
enterProblem:"문제를 입력하세요",
  imageIfAny: "이미지(있는 경우)",
  successEmail:"이메일이 성공적으로 전송되었습니다",
  errorEmail:"이메일 전송 실패",
  overview: "개요",
  dashboard:"계기반",
  manage:"관리하다",
word:"단어",
phraseVerb:"구절",
action:"행동",
delete:"지우다",
detail:"세부 사항"
};

export default ko;
