import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from "react";
import { openNotification } from "../global/item/Notification/Notification";
import { useTranslation } from "react-i18next";
import authService from "../service/auth";



interface AuthContextType {
  hasValidToken: boolean;
  setHasValidToken: (valid: boolean) => void;
  login: (values: { email: string; password: string }) => Promise<void>;
  validateToken: () => void;
  errorMessage: string | null;
  clearErrorMessage: () => void;
  email: string | null;
  username: string | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [hasValidToken, setHasValidToken] = useState<boolean>(() => {
    const token = localStorage.getItem("token");
    const isTokenValidated = localStorage.getItem("isTokenValidated");
    return !!token && isTokenValidated === "true";
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { t } = useTranslation();
  const email = localStorage.getItem("email");
  const username = localStorage.getItem("username");

  // Function to save user details from the token response
  const saveUserDetails = useCallback(
    (user: { _id: string; email: string; username: string }) => {
      const { _id, email, username } = user;
      if (_id) localStorage.setItem("id", _id);
      if (email) localStorage.setItem("email", email);
      if (username) localStorage.setItem("username", username);
    },
    []
  );

  // Function to validate token
  const validateToken = useCallback(
    async (forceValidate: boolean = false) => {
      const token = localStorage.getItem("token");

      if (token) {
        const isTokenValidated = localStorage.getItem("isTokenValidated");

        if (isTokenValidated === "true" && !forceValidate) {
          setHasValidToken(true);
          return;
        }

        try {
          const response = await authService.getToken(token);
          const user = response.data.user;

          saveUserDetails(user);
          setHasValidToken(true);
          localStorage.setItem("isTokenValidated", "true");
          setErrorMessage(null);
        } catch (error) {
          console.error("Token validation failed", error);
          setHasValidToken(false);
          setErrorMessage("Token validation failed. Please try again.");
          localStorage.clear();
        }
      } else {
        setHasValidToken(false);
        setErrorMessage("No token found. Please log in.");
      }
    },
    [saveUserDetails]
  );

  // Login function
  const login = useCallback(
    async (values: { email: string; password: string }) => {
      const loginData = {
        email: values.email,
        password: values.password,
        authMethod: "email",
      };
      try {
        const response = await authService.login(loginData);
        openNotification({
          type: "success",
          message: t("success"),
          description: t("LoginSuccess"),
        });

        const { token } = response.data;
        if (token) {
          localStorage.setItem("token", token);
          setHasValidToken(true);
          await validateToken(); // Validate token after login
        }
      } catch (error) {
        openNotification({
          type: "error",
          message: t("error"),
          description: t("loginFailure"),
        });
      }
    },
    [t, validateToken]
  );

  useEffect(() => {
    validateToken();

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "token") {
        validateToken(true); // Re-validate if token changes
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [validateToken]);

  const clearErrorMessage = () => setErrorMessage(null);

  return (
    <AuthContext.Provider
      value={{
        hasValidToken,
        setHasValidToken,
        login,
        validateToken,
        errorMessage,
        clearErrorMessage,
        email,
        username,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
