import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import vocab from "../../service/vocab";
import { VocabState } from "../../@type/vocab";


export const getVocabByUserId = createAsyncThunk(
  "get/getVocabByUserId",
  async (id: any) => {
    const { data } = await vocab.getByUserId(id);
    return data;
  }
);
export const getVocab = createAsyncThunk(
  "get/getVocab",
  async (params: any) => {
    const { data } = await vocab.get(params);
    return data;
  }
);
export const getVocabById = createAsyncThunk(
  "get/getVocabById",
  async (id: any) => {
    const { data } = await vocab.getById(id);
    return data;
  }
);
export const getVocabQuiz = createAsyncThunk(
  "get/getVocabQuiz",
  async (id: any) => {
    const { data } = await vocab.getVocabQuiz(id);
    return data;
  }
);
export const createVocab = createAsyncThunk(
  "get/createVocab",
  async (formData: FormData) => {
    const { data } = await vocab.post(formData);
    return data;
  }
);
export const deleteVocab = createAsyncThunk(
  "delete/deleteVocab",
  async (_id: string) => {

    await vocab.delete(_id);
    return _id; 
  }
);
const initialState: VocabState = {
  vocabList: [],
  vocabDetail:{
    _id:"",
    antonym:"",
    category:[],
    context:"",
    example:"",
    image:"",
    meaning:"",
    name:"",
    note:"",
    phrase:"",
    synonym:"",
    topic:"",
    transcription:"",
    user:""
  }

};

const wordformSlice = createSlice({
  name: "vocab",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createVocab.fulfilled, (state, action) => {
      state.vocabList = action.payload;
    });
    builder.addCase(getVocab.fulfilled, (state, action) => {

      state.vocabList = action.payload.vocabs;

    });
    builder.addCase(getVocabQuiz.fulfilled, (state, action) => {

      state.vocabList = action.payload.vocab;

    });
    builder.addCase(getVocabByUserId.fulfilled, (state, action) => {
      state.vocabList = action.payload.vocab;
    });
    builder.addCase(deleteVocab.fulfilled, (state, action) => {
      state.vocabList = state.vocabList.filter((item) => item._id !== action.payload);
    }); 
    builder.addCase(getVocabById.fulfilled, (state, action) => {
      state.vocabDetail = action.payload.vocab;
    }); 
  },
});

export default wordformSlice.reducer;
