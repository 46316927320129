import { useEffect, useState } from "react";
import { Button, Col, Row, Form, Input, Flex } from "antd";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import Loading from "../../global/item/Loading/loading";
import authService from "../../service/auth";
import UseRespon from "../../../hook/useResponsive";
import signInAnimation from "../../../accset/json/signInJson.json";
import "./SignIn.scss";
import { openNotification } from "../../global/item/Notification/Notification";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import {jwtDecode} from "jwt-decode";
import Translation from "../../global/item/Translation/Translation";
import { GoogleLogin } from "@react-oauth/google";

function SignIn() {
  const { setHasValidToken, hasValidToken, login } = useAuth();
  const { t } = useTranslation();
  const { isMobile } = UseRespon();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (hasValidToken) {
      navigate("/Dashboard");
    }
  }, [hasValidToken, navigate]);

  const onFinish = async (values: { email: string; password: string }) => {
    setLoading(true);
    await login(values);
    setLoading(false);
  };

  const handleGoogleLoginSuccess = async (credentialResponse: any) => {
    setLoading(true);
    if (credentialResponse.credential) {
      try {
        const decoded: any = jwtDecode(credentialResponse.credential);
        const { email, name } = decoded;

        const response = await authService.loginGoogle({
          email,
          authMethod: "google",
          username: name,
        });

        openNotification({
          type: "success",
          message: t("success"),
          description: t("LoginSuccess"),
        });

        const { token } = response.data;
        if (token) {
          localStorage.setItem("token", token);
          setHasValidToken(true);
          navigate("/Dashboard");
        }
      } catch (error) {
        openNotification({
          type: "error",
          message: t("error"),
          description: t("loginFailure"),
        });
      } finally {
        setLoading(false); // Fix here
      }
    }
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div style={{ background: "#f2f2f2" }}>
          <Row style={{ height: "100vh" }}>
            {!isMobile && (
              <Col xl={16}>
                <div style={{ height: "50px", borderRadius: "10px", margin: "10px 40px" }}>
                  <Flex>
                    <h2 style={{ color: `var(--primary-color)`, fontFamily: "Playwrite CU, sans-serif" }}>
                      E<span style={{ color: "#000" }}>learning</span>
                    </h2>
                  </Flex>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "calc(100vh - 90px)" }}>
                  <Lottie animationData={signInAnimation} style={{ width: 550 }} />
                </div>
              </Col>
            )}
            <Col xl={8} xs={24} className="signInRight">
              <Translation />
              <div className="signInRight__container">
                <h2 className="signInRight--title">{t("welcome")}</h2>
                <p className="signInRight--desc">{t("signInAccount")}</p>
                <Form name="basic" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={onFinish} autoComplete="off">
                  <Form.Item
                    className="formItem"
                    name="email"
                    rules={[
                      { required: true, message: t("emailRequired") },
                      {
                        validator: (_, value) =>
                          !value || value.endsWith("@gmail.com")
                            ? Promise.resolve()
                            : Promise.reject(new Error(t("emailGmailRequired"))),
                      },
                    ]}
                  >
                    <div className="form-field">
                      <Input className="form-input" placeholder=" " />
                      <label htmlFor="email" className="form-label">
                        {t("email")}
                      </label>
                    </div>
                  </Form.Item>
                  <Form.Item name="password" style={{ marginBottom: 0 }} rules={[{ required: true, message: t("passwordRequired") }]}>
                    <div className="form-field">
                      <Input className="form-input" type="password" placeholder=" " />
                      <label htmlFor="password" className="form-label">
                        {t("password")}
                      </label>
                    </div>
                  </Form.Item>
                  <Link to={"/forgot-password"}>
                    <p style={{ margin: "10px", display: "flex", justifyContent: "flex-end" }}>{t("forgotPass")}</p>
                  </Link>
                  <Button type="primary" htmlType="submit" style={{ width: "100%", height: 46, fontWeight: 600 }}>
                    {t("signIn")}
                  </Button>
                  <p style={{ margin: "5px", display: "flex" }}>
                    {t("noAccount")}
                    <Link to="/register" style={{ paddingLeft: 5 }}>
                      {t("registerNow")}
                    </Link>
                  </p>
                  <div className="or-separator">
                    <span className="line"></span>
                    <span className="text">{t("or")}</span>
                    <span className="line"></span>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <GoogleLogin
                      onSuccess={handleGoogleLoginSuccess}
                      onError={() => console.log("Login Failed")}
                      type="icon"
                      theme="outline"
                      shape="circle"
                      size="large"
                    />
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default SignIn;
