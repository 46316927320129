import React from 'react'

function ElipsCustom() {
  return (
   <div style={{display:"flex", justifyContent:"center"}}>
<svg fill="#000000" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className='actionIcon'>
          <circle cx="17.5" cy="12" r="1.5"/>
          <circle cx="12" cy="12" r="1.5"/>
          <circle cx="6.5" cy="12" r="1.5"/>
        </svg>
   </div>
       

  )
}

export default ElipsCustom
