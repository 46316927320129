import React, { useState, useEffect } from "react";
import axios from "axios";
import { Radio, Button, Typography, message, Row, Col } from "antd";
import type { RadioChangeEvent, } from "antd";
import TitleComponent from "../../global/Custom/TitleComponent/TitleComponent";
import {
  SoundOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import useSpeechSynthesis from "../../context/speech";

type VocabItem = {
  _id: string;
  name: string;
  meaning: string;
};

const { Text } = Typography;

const shuffleArray = <T,>(array: T[]): T[] => {
  let shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

function SelectAnswer() {
  const { speakText } = useSpeechSynthesis();

  const [questions, setQuestions] = useState<VocabItem[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [feedback, setFeedback] = useState("");
  const [options, setOptions] = useState<string[]>([]);
  const [previousOptions, setPreviousOptions] = useState<string[]>([]);
  const { t } = useTranslation();
  const fetchQuestions = async () => {
    try {
      const userId = localStorage.getItem("id");
      if (!userId) {
        message.error("User ID not found in localStorage");
        return;
      }

      const response = await axios.get<{ vocab: VocabItem[] }>(
        `https://be-elearning.onrender.com/vocab/quiz/${userId}`
      );
      const shuffledQuestions = shuffleArray(response.data.vocab);
      setQuestions(shuffledQuestions);
      setCurrentQuestionIndex(0);
    } catch (error) {
      message.error("Error fetching questions");
      console.error("Error fetching questions:", error);
    }
  };
  const handleCollapseChange = (text: any) => {
    if ("speechSynthesis" in window) {
      console.log("Speaking:", text);
      speakText(text);
    } else {
      console.log("SpeechSynthesis is not supported in this browser.");
    }
  };
  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    const generateOptions = () => {
      if (questions.length > 0) {
        const currentQuestion = questions[currentQuestionIndex];
        const incorrectOptions = questions
          .filter((q) => q._id !== currentQuestion._id)
          .map((q) => q.meaning)
          .slice(0, 10); // Fetch more incorrect options to ensure we have enough

        let allOptions = [currentQuestion.meaning, ...incorrectOptions];

        // Exclude previous options
        allOptions = allOptions.filter(
          (option) => !previousOptions.includes(option)
        );

        // Shuffle and pick 3 incorrect options
        const shuffledOptions = shuffleArray(allOptions);
        const selectedIncorrectOptions = shuffledOptions
          .filter((option) => option !== currentQuestion.meaning)
          .slice(0, 3);

        // Combine with the correct option
        allOptions = [currentQuestion.meaning, ...selectedIncorrectOptions];

        // Ensure we have exactly 4 options
        allOptions = shuffleArray(allOptions).slice(0, 4);

        setOptions(allOptions);
      }
    };

    generateOptions();
  }, [currentQuestionIndex, questions, previousOptions]);

  const handleOptionChange = (e: RadioChangeEvent) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = async () => {
    const currentQuestion = questions[currentQuestionIndex];
  
    if (selectedOption === currentQuestion.meaning) {
      handleCollapseChange(currentQuestion.name);
      setFeedback("Correct!");
      message.success("Correct!");
      setTimeout(async () => {
        setSelectedOption("");
        setFeedback("");

        // Update previous options
        setPreviousOptions(options);

        // Remove the current question from the list
        const updatedQuestions = questions.filter(
          (q, index) => index !== currentQuestionIndex
        );

        if (updatedQuestions.length === 0) {
          // If no more questions left, fetch new questions
          await fetchQuestions();
        } else {
          // Update the questions list and move to the next question
          setQuestions(updatedQuestions);
          setCurrentQuestionIndex((prevIndex) =>
            prevIndex < updatedQuestions.length ? prevIndex : 0
          );
        }
      }, 1000);
    } else {
      setFeedback("Incorrect, try again!");
      message.error("Incorrect answer. Try again!");
    }
  };

  if (questions.length === 0) {
    return <div>Loading...</div>;
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div
      style={{
        margin: "auto",
        height: "100%",
        padding: "20px",
        textAlign: "center",
      }}
    >
      <TitleComponent title={t("Kiểm tra từ vựng")} isExist={false} />

      <div style={{ marginBottom: "20px" }}>
        <Text strong>What is the meaning of the word:</Text>
        <div style={{ fontSize: "24px", fontWeight: "bold", margin: "10px 0" }}>
          {currentQuestion.name}
          <SoundOutlined
            style={{ cursor: "pointer", marginLeft:5 }}
            onClick={(e: any) => {
              e.stopPropagation();
              handleCollapseChange(currentQuestion.name);
            }}
          />
        </div>
      </div>
<div>
  
</div>
      <Radio.Group
        onChange={handleOptionChange}
        value={selectedOption}
        style={{ width: "100%" }}
      >
        <Row gutter={[24, 24]} style={{ width: "100%" }}>
          {options.map((option, index) => (
            <Col xs={24} sm={12} key={index}>
              <div
                className={`border-animation ${
                  selectedOption === option ? "selected-option" : ""
                }`}
                style={{
                  padding: "10px",
                  border:
                    selectedOption === option
                      ? "2px solid #1890ff"
                      : "1px solid #d9d9d9",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                  height: 120,
                  
                }}
              >
                <Radio
                  value={option}
                  style={{ fontSize: "16px", width: "100%", height: "100%",alignItems:'center' }}
                >
                  {option}
                </Radio>
              </div>
            </Col>
          ))}
        </Row>
      </Radio.Group>

      <Button
        type="primary"
        onClick={handleSubmit}
        size="large"
        style={{ width: "100%", marginTop: "20px" }}
      >
       Kiểm tra
      </Button>

      {feedback && (
        <Text
          style={{
            marginTop: "20px",
            fontSize: "18px",
            color: feedback === "Correct!" ? "green" : "red",
          }}
        >
          {feedback}
        </Text>
      )}
    </div>
  );
}

export default SelectAnswer;
