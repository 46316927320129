import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Select, Table } from "antd";

import "./TableComponent.scss";
import Pagination from "../../item/Pagination/Pagination";

// Define types
type ColumnType = {
  title: string;
  dataIndex: string;
};

type DataType = {
  key: string | number;
  [key: string]: any;
};

type Props = {
  columns: ColumnType[];
  data: DataType[];
  onChangePage: (pageIndex: number, pageSize: number) => void;
};

const TableComponent = ({ columns, data, onChangePage }: Props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalSelect, setIsModalSelect] = useState(false);
  const [customPageSizeInput, setCustomPageSizeInput] = useState("");
  const [params, setParams] = useState({
    pageSize: 5, // Default page size
    pageIndex: 1,
  });

  // Calculate total items and pages
  const totalItems = data.length;
  const pageSize = params.pageSize || 5; // Ensure pageSize has a default value
  const totalPages = Math.ceil(totalItems / pageSize);

  // Adjust page index if out of bounds
  useEffect(() => {
    if (params.pageIndex > totalPages && totalPages > 0) {

      setParams((prevParams) => ({
        ...prevParams,
        pageIndex: totalPages,
      }));
    }
  }, [params.pageSize, params.pageIndex, totalItems, totalPages]);

  // Handle page and page size changes
  const handlePageChange = (pageIndex: number, pageSize: number) => {

    setParams({
      pageIndex,
      pageSize,
    });
    onChangePage(pageIndex, pageSize);
  };

  // Get data for the current page
  const currentPageData = data.slice(
    (params.pageIndex - 1) * pageSize,
    params.pageIndex * pageSize
  );



  return (
    <div className="tableComponent">
      <Table
        rowSelection={{
          selectedRowKeys,
          onChange: (keys: React.Key[]) => setSelectedRowKeys(keys),
        }}
        columns={columns}
        dataSource={currentPageData} // Use current page data
        pagination={false}
      />
      <div className="table__footer">
        <div className="table__footer__col">
          <p className="table__footer__col__title">Số hàng mỗi trang</p>
          <Select
            className="table__footer__select"
            style={{ width: 58 }}
            value={params.pageSize}
            onChange={(value) => {
              console.log(`Select changed to value: ${value}`);
              // Convert value to number or use default size
              const newSize = typeof value === "number" ? value : (parseInt(value as string, 10) || pageSize);
              handlePageChange(1, newSize); // Reset to page 1 when page size changes
            }}
            bordered={false}
            suffixIcon={null}
          >
            <Select.Option value={5}>5</Select.Option>
            <Select.Option value={10}>10</Select.Option>
            <Select.Option value={20}>20</Select.Option>
            {/* <Select.Option value="openModal">Nhập</Select.Option> */}
          </Select>
        </div>

        <Pagination
          current={params.pageIndex}
          total={totalItems} // Pass the total number of items
          pageSize={pageSize}
          onChange={handlePageChange}
        />
      </div>

      {/* Modal for custom page size */}
      <Modal
        centered
        open={isModalSelect}
        onOk={() => {
          const pageSize = parseInt(customPageSizeInput, 10);
          if (customPageSizeInput && !isNaN(pageSize) && pageSize > 0) {
            console.log(`Modal OK clicked with custom Page Size: ${pageSize}`);
            handlePageChange(1, pageSize); // Reset to page 1 for custom size
            setIsModalSelect(false);
          }
        }}
        onCancel={() => setIsModalSelect(false)}
        className="modal__product modal__selectPagination"
        okType="danger"
      >
        <Form autoComplete="off">
          <Form.Item
            label="Số item trên trang"
            rules={[
              { required: true, message: "Vui lòng nhập số!" },
              { validator: (_, value) => (!isNaN(Number(value)) && Number(value) > 0 ? Promise.resolve() : Promise.reject("Vui lòng nhập số lớn hơn 0")) },
            ]}
          >
            <Input
              placeholder="Số item"
              value={customPageSizeInput}
              onChange={(e) => setCustomPageSizeInput(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TableComponent;
