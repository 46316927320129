import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Input, Row } from "antd";
import {
  SearchOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { dispatch, useSelector } from "../../../redux/store";
import { getWordform } from "../../../redux/action/grammar/wordform";
import TitleComponent from "../../../global/Custom/TitleComponent/TitleComponent";
import UseRespon from "../../../../hook/useResponsive";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Loading from "../../../global/item/Loading/loading";
import { useNavigate } from "react-router-dom";

function WordformList() {
  const { t } = useTranslation();
  const { wordformList } = useSelector((state) => state.wordform);
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = UseRespon().isMobile ? 2 : 6;
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(true);
  const { isMobile } = UseRespon();
const navigate = useNavigate()
  useEffect(() => {
    setLoading(false)
    dispatch(getWordform({}));
  }, []); 

  const filteredItems = Array.isArray(wordformList) 
  ? wordformList.filter(item =>
      item.name.toLowerCase().includes(value.toLowerCase())
    )
  : [];

  const currentItems = filteredItems.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handleChange = (e: any) => {
    setValue(e.target.value);
    setCurrentPage(0);
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const handleNextPage = () => {
    if ((currentPage + 1) * itemsPerPage < filteredItems.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleWordform = (id: string) => {
    navigate(`/Grammar/Wordform/Detail?id=${id}`);
  };
  
  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <TitleComponent
            title={t("wordform")}
            type="primary"
            data={t("add")}
            to="/Grammar/Wordform/create"
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 20,
            }}
          >
            <Input
              value={value}
              onChange={handleChange}
              placeholder={isFocused ? t('searchItem') : ""}
              prefix={
                <SearchOutlined
                  style={{
                    color: isFocused ? 'blue' : 'black',
                  }}
                />
              }
              style={{
                width: isMobile ? "50%" : "32%",
                boxShadow: "none",
                border: "none",
                borderRadius: 0,
                borderBottom: isFocused ? "1px solid #d9d9d9" : "none",
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <div style={{ display: "flex", gap: 10 }}>
              <Button
                className="buttonPointer"
                icon={<ArrowLeftOutlined />}
                onClick={handlePreviousPage}
                disabled={currentPage === 0}
              />
              <Button
                className="buttonPointer"
                icon={<ArrowRightOutlined />}
                onClick={handleNextPage}
                disabled={(currentPage + 1) * itemsPerPage >= filteredItems.length}
              />
            </div>
          </div>
          <Row gutter={[20, 5]}>
            <TransitionGroup component={null}>
              {currentItems.map((item, index) => (
                <CSSTransition
                  key={index}
                  timeout={500}
                  classNames="fade" // Define CSS classes for transitions
                >
                  <Col xl={8}>
                    <Card className="layoutItem cardItem">
                      <div
                        style={{ display: "flex", justifyContent: "space-between" }}
                      >
                        <div className="image-container">
                          <img
                            src={item.image}
                            alt={item.name}
                            style={{ borderRadius: 10, height:140 }}
                            loading="lazy"
                          />
                        </div>
                        <div style={{ flexDirection: "column" }}>
                          <h3 style={{ margin: 0 }}>{item.name}</h3>
                          <p>Số từ: 100</p>
                          <p>Số chủ đề: 10</p>
                          <Button type="primary" onClick={() => handleWordform(item._id)}>Học ngay</Button>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </Row>
        </div>
      )}
    </div>
  );
}

export default WordformList;
