import React, { useCallback, useEffect, useMemo, useState } from "react";
import TitleComponent from "../../global/Custom/TitleComponent/TitleComponent";
import TableComponent from "../../global/Custom/TableComponent/TableComponent";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  Col,
  Collapse,
  Dropdown,
  Flex,
  Input,
  Modal,
  Row,
  Select,
  Tag,
  Typography,
} from "antd";
import { dispatch, useSelector } from "../../redux/store";

import { getWordform } from "../../redux/action/grammar/wordform";
import UseRespon from "../../../hook/useResponsive";

import {
  SearchOutlined,
  SoundOutlined,
  EditOutlined,
  DeleteOutlined,
  CalendarOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import {
  deleteVocab,
  getVocabById,
  getVocabByUserId,
} from "../../redux/action/vocab";

import useSpeechSynthesis from "../../context/speech";
import ElipsCustom from "../../../accset/svg/elips";
import { openNotification } from "../../global/item/Notification/Notification";
import Paragraph from "antd/es/typography/Paragraph";
import { getTopic } from "../../redux/action/topic";

const { Panel } = Collapse;
const { Option } = Select;

function VocabList() {
  const { t } = useTranslation();
  const { isMobile } = UseRespon();
  const { vocabList, vocabDetail } = useSelector((state) => state.vocab);
  const { wordformList } = useSelector((state) => state.wordform);

  const { topicList } = useSelector((state) => state.topic);
  const [value, setValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [id, setId] = useState<string | null>(null);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDetailOpen, setIsModalDetailOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [selectedIdDetail, setSelectedIdDetail] = useState<string | null>(null);
  const { speakText } = useSpeechSynthesis();

  useEffect(() => {
    const storedId = localStorage.getItem("id");
    if (storedId) {
      setId(storedId);
    }
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getVocabByUserId(id));
    }
    dispatch(getWordform({}));
    dispatch(getTopic({}));
  }, [id]);

  useEffect(() => {
    if (selectedIdDetail) {
      dispatch(getVocabById(selectedIdDetail));
    }
  }, [selectedIdDetail]);

  const showModal = (id: any) => {
    setSelectedId(id);
    setIsModalOpen(true);
  };

  const handleOkDelete = async () => {
    try {
      if (selectedId) {
        await dispatch(deleteVocab(selectedId));
        openNotification({
          type: "success",
          message: t("success"),
        });
      }
      setSelectedId(null);
    } catch (error) {
      openNotification({
        type: "error",
        message: t("error"),
      });
    } finally {
      setIsModalOpen(false); // Close the modal
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedId(null);
  };
  const showModalDetail = (id: any) => {
    setIsModalDetailOpen(true);
    setSelectedIdDetail(id);
  };

  const handleCancelDetail = () => {
    setIsModalDetailOpen(false);
    setSelectedIdDetail(null);
  };
  const onChangePage = (pageIndex: number, pageSize: number) => {
    console.log(`Page: ${pageIndex}, PageSize: ${pageSize}`);
  };
  const handleCollapseChange = (text: any) => {
    if ("speechSynthesis" in window) {
      console.log("Speaking:", text);
      speakText(text);
    } else {
      console.log("SpeechSynthesis is not supported in this browser.");
    }
  };
  const handleCollapse = (key: any) => {
    if ("speechSynthesis" in window) {
      const activePanel = items.find(
        (item) => item.key === key[key.length - 1]
      );
      if (activePanel) {
        console.log("Speaking:", activePanel.textToRead);
        speakText(activePanel.textToRead);
      } else {
        console.log("Active panel not found.");
      }
    } else {
      console.log("SpeechSynthesis is not supported in this browser.");
    }
  };
  const categoryColorMap: {
    "Tính từ": string;
    "Danh từ": string;
    "Động từ": string;
    "Trạng từ": string;
    "Liên từ": string;
    "Đại từ": string;
    "Giới từ": string;
  } = {
    "Tính từ": "red",
    "Danh từ": "blue",
    "Động từ": "green",
    "Trạng từ": "orange",
    "Liên từ": "purple",
    "Đại từ": "teal",
    "Giới từ": "pink",
  };
  const getColor = (cat: string) =>
    categoryColorMap[cat as keyof typeof categoryColorMap];
  const itemsDrop = (id: any) => [
    {
      key: "1",
      label: (
        <div onClick={() => showModalDetail(id)}>
          <span style={{ marginBottom: 20 }}>
            <CalendarOutlined style={{ marginRight: 8 }} />
            {t("detail")}
          </span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <span style={{ marginBottom: 20 }}>
          <EditOutlined style={{ marginRight: 8 }} />
          {t("edit")}
        </span>
      ),
    },
    {
      key: "3",
      label: (
        <div onClick={() => showModal(id)}>
          <span>
            <DeleteOutlined style={{ marginRight: 8 }} />
            {t("delete")}
          </span>
        </div>
      ),
    },
  ];
  const columns = [
    {
      title: t("vocab"),
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (text: any, record: any) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            transition: "color 0.3s",
          }}
          onClick={() => handleCollapseChange(text)}
          onMouseEnter={(e) => (e.currentTarget.style.color = "blue")}
          onMouseLeave={(e) => (e.currentTarget.style.color = "black")}
        >
          <span style={{ marginRight: "8px", fontWeight: 500 }}>{text}</span>
          <SoundOutlined
            style={{ cursor: "pointer" }}
            onClick={(e: any) => {
              e.stopPropagation();
              handleCollapseChange(text);
            }}
          />
        </div>
      ),
    },
    {
      title: t("transcrip"),
      dataIndex: "transcription",
      key: "transcription",
      width: "20%",
    },
    {
      title: t("wordform"),
      dataIndex: "category",
      key: "category",
      width: "20%",
      render: (category: string) => {
        const categories = category.split(", ");
        return categories.map((cat, index) => (
          <span key={index} style={{ color: getColor(cat.trim()) || "black" }}>
            {cat}
            {index < categories.length - 1 ? ", " : ""}
          </span>
        ));
      },
    },
    {
      title: t("meaning"),
      dataIndex: "meaning",
      key: "meaning",
      width: "20%",
      render: (text: any) => (
        <div
          className="truncate-two-lines"
          dangerouslySetInnerHTML={{ __html: text }} // Hiển thị HTML
        />
      ),
    },

    {
      title: t("action"),
      dataIndex: "meaning",
      key: "action",
      width: "20%",
      render: (text: any, record: any) => (
        <div style={{ width: "50px", margin: "0 auto" }}>
          <Dropdown menu={{ items: itemsDrop(record.key) }} trigger={["hover"]}>
            <span>
              <ElipsCustom />
            </span>
          </Dropdown>
        </div>
      ),
    },
  ];

  const filteredData = useMemo(() => {
    return Array.isArray(vocabList)
      ? vocabList.filter((item: any) => {
          const matchesSearchTerm = item.name
            ?.toLowerCase()
            .includes(value.toLowerCase());
          const matchesWordform =
            selectedValues.length === 0 ||
            item.category?.some((categoryId: string) =>
              selectedValues.includes(categoryId)
            );
          return matchesSearchTerm && matchesWordform;
        })
      : [];
  }, [vocabList, value, selectedValues]);

  const data = filteredData.map((item: any) => {
    // Map over item.category array to get names
    const categoryNames = item.category
      .map((categoryId: string) => {
        const category = wordformList.find(
          (wordform: any) => wordform._id === categoryId
        );
        return category ? category.name : "Unknown";
      })
      .join(", "); // Join names with a comma

    return {
      key: item._id,
      name: item.name,
      category: categoryNames,
      transcription: item.transcription,
      meaning: item.meaning,
    };
  });
  const options = wordformList.map((item: any) => ({
    value: item._id,
    label: item.name,
  }));

  const handleSelectChange = (values: string[]) => {
    setSelectedValues(values);
  };

  const handleChange = useCallback((e: any) => {
    setValue(e.target.value);
  }, []);
  const handleFocus = useCallback(() => setIsFocused(true), []);
  const handleBlur = useCallback(() => setIsFocused(false), []);
  const items = filteredData.map((vocab) => ({
    key: vocab._id,
    label: `${vocab.name} (${vocab.transcription})`,
    children: (
      <div>
        <p style={{ margin: "10px 0 10px 0" }}>
          <strong>Nghĩa của từ:</strong> {vocab.meaning}
        </p>
        <p style={{ margin: "10px 0 10px 0" }}>
          <strong>Example:</strong> {vocab.example}
        </p>
        <p style={{ margin: "10px 0 10px 0" }}>
          <strong>Transcription:</strong> {vocab.transcription}
        </p>
        <strong
          style={{ margin: "10px 0 10px 0", color: `var(--primary-color)` }}
          onClick={() => showModalDetail(vocab._id)}
        >
          Chi tiết
        </strong>
      </div>
    ),
    textToRead: vocab.name,
  }));
  const topic = topicList.find((t) => vocabDetail.topic.includes(t._id));
  const categoryNames = wordformList
    .filter((wordform) => vocabDetail.category.includes(wordform._id))
    .map((wordform) => wordform.name);
  const colors = ["blue", "green", "red", "purple", "orange"]; // Add more colors as needed
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = UseRespon().isMobile ? 10 : 6;
  const useDebounce = (value: any, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };
  const debouncedValue = useDebounce(value, 300);

  const filteredItems = useMemo(() => {
    return Array.isArray(filteredData)
      ? filteredData.filter((item) =>
          item.name.toLowerCase().includes(debouncedValue.toLowerCase())
        )
      : [];
  }, [filteredData, debouncedValue]);

  // Paginated items
  const paginatedItems = useMemo(() => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return items.slice(startIndex, endIndex); // Slice items, not filteredData directly
  }, [items, currentPage, itemsPerPage]);

  const handlePreviousPage = useCallback(() => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage]);

  const handleNextPage = useCallback(() => {
    if ((currentPage + 1) * itemsPerPage < filteredItems.length) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, filteredItems.length, itemsPerPage]);

  return (
    <div>
      <TitleComponent
        title={t("vocab")}
        type="primary"
        data={t("add")}
        to="/Vocab/Create"
      />

      <Row gutter={[20, 12]}>
        <Col xl={12} xs={24}>
          <Input
            value={value}
            onChange={handleChange}
            placeholder={isFocused ? t("searchItem") : ""}
            prefix={
              <SearchOutlined
                style={{
                  color: isFocused ? "blue" : "black",
                }}
              />
            }
            style={{
              width: UseRespon().isMobile ? "100%" : "100%",
              boxShadow: "none",
              border: "none",
              borderRadius: 0,
              borderBottom: isFocused ? "1px solid #d9d9d9" : "none",
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Col>
        <Col xl={12} xs={24}>
          <Row gutter={{ xs: 12, xl: 20 }}>
            <Col xl={12} xs={12}>
              <Select
                mode="multiple"
                value={selectedValues}
                placeholder="Chọn từ loại"
                className="selectForm"
                style={{ width: "100%" }}
                onChange={handleSelectChange}
              >
                {options.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xl={12} xs={12}>
              <Select
                mode="multiple"
                placeholder="Chọn chủ đề"
                className="selectForm"
                style={{ width: "100%" }}
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                  { value: "Yiminghe", label: "Yiminghe" },
                ]}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {isMobile ? (
        <div>
          <div style={{ display: "flex", gap: 10, justifyContent: "flex-end" , marginTop:16}}>
            <Button
              className="buttonPointer"
              icon={<ArrowLeftOutlined />}
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
            />
            <Button
              className="buttonPointer"
              icon={<ArrowRightOutlined />}
              onClick={handleNextPage}
              disabled={
                (currentPage + 1) * itemsPerPage >= filteredItems.length
              }
            />
          </div>
          <Collapse
  defaultActiveKey={["1"]}
  onChange={handleCollapse}
  className="custom-collapse"
  style={{
    background: "none",
    border: "none",
    margin: "20px 0",
    maxHeight: "400px", // Set a maximum height
    overflowY: "auto", // Enable vertical scrolling
  }}
>
  {paginatedItems.map((item) => (
    <Panel header={item.label} key={item.key}>
      {item.children}
    </Panel>
  ))}
</Collapse>
        </div>
      ) : (
        <TableComponent
          columns={columns}
          data={data}
          onChangePage={onChangePage}
        />
      )}
      <Modal
        title="Xóa từ"
        open={isModalOpen}
        centered
        footer={false}
        width={330}
        onCancel={handleCancel}
        zIndex={99999}
      >
        <p>Bạn có chắc chắn xoá từ này không?</p>
        <div style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}>
          <Button style={{ padding: "0 20px" }} onClick={handleCancel}>
            Hủy
          </Button>
          <Button type="primary" onClick={handleOkDelete}>
            Tiếp tục
          </Button>
        </div>
      </Modal>

      <Modal
        open={isModalDetailOpen}
        centered
        footer={false}
        width={1000}
        onCancel={handleCancelDetail}
        zIndex={99999}
        closeIcon={false}
        style={isMobile ? { padding: 16 } : undefined}
      >
        {isMobile ? (
          <div>
            <div className="animated-border">
              <Row style={{ alignItems: "center" }}>
                <Typography.Title
                  level={2}
                  style={{
                    margin: 0,
                    fontSize: 22,
                    fontWeight: 700,
                    color: "var(--primary-color)",
                    width: "100%",
                    textAlign:'center',
                    marginBottom:6,
                    textTransform: 'capitalize',
                  }}
                >
                  {vocabDetail.name}
                </Typography.Title>
                <Col xs={18}>
                <p
                      style={{
                        margin: 0,
                        fontSize: "18px",
                      }}
                    >
                      {vocabDetail.transcription}
                    </p>
                  <div>
                    {categoryNames.map((name, index) => (
                      <Tag
                        key={index}
                        color={colors[index % colors.length]}
                        style={{
                          fontSize: "14px",
                          fontWeight: 500,
                          marginTop: "8px",
                          marginRight: "4px",
                        }}
                      >
                        {name}
                      </Tag>
                    ))}
                  </div>
                </Col>
                <Col xs={6}>
                  <Flex justify="flex-end">
                    <SoundOutlined
                      style={{ cursor: "pointer" }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        handleCollapseChange(vocabDetail.name);
                      }}
                    />
                  </Flex>
                </Col>
              </Row>
              <div className="animated-border-inner"></div>
            </div>
          </div>
        ) : (
          <div style={{ padding: "20px" }}>
            <div className="animated-border">
              <Row style={{ alignItems: "center" }}>
                <Col xl={12}>
                  <Flex align="center" gap={20}>
                    <Typography.Title
                      level={2}
                      style={{
                        margin: 0,
                        fontSize: "28px",
                        fontWeight: 700,
                        color: "var(--primary-color)",
                      }}
                    >
                      {vocabDetail.name}
                    </Typography.Title>
                    <p
                      style={{
                        margin: 0,
                        fontSize: "18px",
                      }}
                    >
                      {vocabDetail.transcription}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        transition: "color 0.3s",
                      }}
                      onClick={() => handleCollapseChange(vocabDetail.name)}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.color = "blue")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.color = "black")
                      }
                    >
                      <SoundOutlined
                        style={{ cursor: "pointer" }}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          handleCollapseChange(vocabDetail.name);
                        }}
                      />
                    </div>
                  </Flex>
                  <div>
                    {categoryNames.map((name, index) => (
                      <Tag
                        key={index}
                        color={colors[index % colors.length]}
                        style={{
                          fontSize: "14px",
                          fontWeight: 500,
                          marginTop: "8px",
                          marginRight: "4px",
                        }}
                      >
                        {name}
                      </Tag>
                    ))}
                  </div>
                </Col>
                <Col xl={12}>
                  <Flex justify="flex-end">
                    <Button type="primary">{topic?.name}</Button>
                  </Flex>
                </Col>
              </Row>
              <div className="animated-border-inner"></div>
            </div>
          </div>
        )}

        <Row gutter={[16, 16]} style={{ maxHeight: "50vh", overflowY: "auto" }}>
          {vocabDetail.meaning && (
            <Col xl={12} xs={24}>
              <Card
                title={<span style={{ color: "#5c9aff" }}>{t("meaning")}</span>}
                bordered={false}
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  height: isMobile ? 150 : 200,
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    maxHeight: "100px",
                    overflowY: "auto",
                    lineHeight: "1.5em",
                  }}
                  dangerouslySetInnerHTML={{ __html: vocabDetail.meaning }}
                />
              </Card>
            </Col>
          )}

          {vocabDetail.example && (
            <Col xl={12} xs={24}>
              <Card
                title={<span style={{ color: "#5c9aff" }}>{t("example")}</span>}
                bordered={false}
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  height: isMobile ? 150 : 200,

                  overflow: "hidden", // Hide overflow
                }}
              >
                <div
                  style={{
                    maxHeight: "100px",
                    overflowY: "auto",
                    lineHeight: "1.5em",
                  }}
                  dangerouslySetInnerHTML={{ __html: vocabDetail.example }}
                />
              </Card>
            </Col>
          )}

          {vocabDetail.synonym && (
            <Col xl={12} xs={24}>
              <Card
                title={
                  <span style={{ color: "#5c9aff" }}>{t("synonymo")}</span>
                }
                bordered={false}
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  height: isMobile ? 150 : 200,

                  overflow: "hidden", // Hide overflow
                }}
              >
                <Paragraph style={{ margin: 0, overflowY: "auto" }}>
                  {vocabDetail.synonym}
                </Paragraph>
              </Card>
            </Col>
          )}

          {vocabDetail.antonym && (
            <Col xl={12} xs={24}>
              <Card
                title={<span style={{ color: "#5c9aff" }}>{t("antonym")}</span>}
                bordered={false}
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  height: isMobile ? 150 : 200,

                  overflow: "hidden", // Hide overflow
                }}
              >
                <Paragraph style={{ margin: 0, overflowY: "auto" }}>
                  {vocabDetail.antonym}
                </Paragraph>
              </Card>
            </Col>
          )}

          {vocabDetail.phrase && (
            <Col xl={12} xs={24}>
              <Card
                title={<span style={{ color: "#5c9aff" }}>{t("phrase")}</span>}
                bordered={false}
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  height: isMobile ? 150 : 200,

                  overflow: "hidden", // Hide overflow
                }}
              >
                <Paragraph style={{ margin: 0, overflowY: "auto" }}>
                  {vocabDetail.phrase}
                </Paragraph>
              </Card>
            </Col>
          )}

          {vocabDetail.context && (
            <Col xl={12} xs={24}>
              <Card
                title={<span style={{ color: "#5c9aff" }}>{t("context")}</span>}
                bordered={false}
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  height: isMobile ? 150 : 200,

                  overflow: "hidden", // Hide overflow
                }}
              >
                <Paragraph style={{ margin: 0, overflowY: "auto" }}>
                  {vocabDetail.context}
                </Paragraph>
              </Card>
            </Col>
          )}

          {vocabDetail.note && (
            <Col xl={12} xs={24}>
              <Card
                title={<span style={{ color: "#5c9aff" }}>{t("note")}</span>}
                bordered={false}
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  height: "200px",
                  overflow: "hidden", // Hide overflow
                }}
              >
                <div
                  className="div_tag"
                  style={{
                    maxHeight: "100px",
                    overflowY: "auto",
                    lineHeight: "1.5em",
                  }}
                  dangerouslySetInnerHTML={{ __html: vocabDetail.note }}
                />
              </Card>
            </Col>
          )}

          {vocabDetail.image && (
            <Col xl={12} xs={24}>
              <Card
                title={<span style={{ color: "#5c9aff" }}>{t("image")}</span>}
                bordered={false}
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  height: isMobile ? 150 : 200,

                }}
              >
                <img
                  src={vocabDetail.image}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",

                    borderRadius: "8px",
                  }}
                  loading="lazy"
                />
              </Card>
            </Col>
          )}
        </Row>
      </Modal>
    </div>
  );
}

export default VocabList;
