import React from "react";
import { Card, Row, Col } from "antd";
import TitleComponent from "../../global/Custom/TitleComponent/TitleComponent";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import animation from "../../../accset/json/test.json";
import { useNavigate } from "react-router-dom";

function Review() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const reviewOptions = [
    {
      title: t("Từ vựng"),
      description: t("vocabulary_review_description"),
      path: '/Review/Vocabulary'
    },
    {
      title: t("Ngữ pháp"),
      description: t("grammar_review_description"),
      path: '/Review/Grammar'
    },
    {
      title: t("Nghe ví dụ"),
      description: t("listening_review_description"),
      path: '/Review/Listening'
    },
    {
      title: t("Tổng hợp"),
      description: t("another_review_description"),
      path: '/Review/Another'
    },
  ];

  const handleReviewWord = (path: string) => {
    navigate(path);
  }

  return (
    <div>
      <TitleComponent title={t("review")} isExist={false} />
      <h2>Chọn hình thức kiểm tra</h2>

      <Row gutter={[24, 16]}>
        {reviewOptions.map((option, index) => (
          <Col xs={24} sm={12} md={6} key={index}>
            <Card
              hoverable
              title={option.title}
              bordered
              style={{ borderRadius: '10px', textAlign: 'center' }}
              onClick={() => handleReviewWord(option.path)}
            >
              <Lottie animationData={animation} style={{ width: 150, margin: "0 auto" }} />
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Review;
