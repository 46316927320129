const en = {
  "layout": {
    "dashboard": "Dashboard",
    "home": {
      "home": "Home",
      "banner": "Banner",
    },
    "banner": {
      "subSection1": "Phần con 1",
      "subSection2": "Phần con 2"
    },
    "success": "Thành công",
    "updateSuccess": "Dữ liệu được cập nhật thành công!"
  },
  "home": {

  },
  "edit": "Edit",
  "basicInformation": "Basic Information",
  "tag": "Tag",
  "title": "Title",
  "describtion": "Describtion",
  "imageInfo": "Image Information",
  "vocab": "Vocabulary",
  "add": "Add now",
  "topic": "Topic",
  "welcome": "Welcome",
  "signInAccount": "Sign in to your account",
  "password": "Password",

  "email": "Email",
  "emailRequired": "Please enter email!",
  "emailGmailRequired": "Please enter the correct email format!",
  "passwordRequired": "Please enter password!",
  "forgotPass": "Forgot Password",
  "youForgot": "Forgot your password?",
  "forgotPassDesc": "Don't worry, please enter your email so we can assist you in updating your password.",
  "signIn": "Sign In",
  "noAccount": "You don't have an account yet?",
  "registerNow": "Register now",
  "or": "Or",
  "usernameRequired": "Please input your username!",
  "username": "Full Name",
  "signUpAccount": "Sign up for your account",
  "useAccount": "Already have an account?",
  "loginNow": "Sign in now",
  "signUp": "Sign Up",
  "otpSentToEmail": "OTP sent to your email",
  "emailExists": "Email already exists",
  "useAnotherEmail": "Please use a different email",
  "success": "Success",
  "error": "Error",
  "LoginSuccess": "Login successfully",
  "loginFailure": "Login Failure",
  "RegisterSuccess": "Register successfully",
  "errorForgot": "Email does not exist or google account cannot be reset",
  "confirm": "Confirm",
  "verifyAccount": "Verify your account",
  "verifyDesc": "Please enter the verification code from your email",
  "otp": "OTP",
  "otpRequired": "Please input your OTP!",
  "verifySuccess": "Verification successful",
  "verifyFailure": "Verification failed",
  "tokenError": "The token has expired or is invalid",
  "grammar": "Grammar",
  "wordform": "Word form",
  basic_information: "Basic Information",
  additional_information: "Additional Information",
  word_type: "Word Type",
  symbol: "Symbol",
  enter_word_type_name: "Enter word type name",
  enter_data_name: "Enter data name",
  please_input_your_data: "Please input your data!",
  image: "Image",
  please_select_an_image: "Please select an image!",
  uploaded_image: "Uploaded Image",
  upload_text: "Click or drag file to this area to upload",
  upload_hint: "Support for a single upload. Click or drag the file here.",
  cancel: "Cancel",
  continue: "Continue",
  successCreate: "Create Success",
  errorCreate: "Create Failure",
  searchItem: "Your search...",
  review: "Review",
  transcrip: "Transcription",
  meaning: "Meaning",
  synonymo: "Synonym",
  antonym: "Antonym",
  phrase: "Phrase",
  context: "Context",
  example: "Example",
  note: "Note",
  logout: "Logout",
  profile: "Profile",
  schedule: "Set a class schedule",
  history: "History",
  needHelp: "Need help? Contact me",
  contact: "Contact me",
  problem: "Problem",
  enterProblem: "Enter your problem",
  imageIfAny: "Image (If Any)",
  successEmail: "Email sent successfully",
  erroeEmail: "Email sending failed",
  overview: "Overview",
  dashboard:"Dashboard",
  manage:"Manage",
word:"Word",
phraseVerb:"Phrase Verb",
action:"Action",
delete:"Delete",
detail:"Detail"
};

export default en;
