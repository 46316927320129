// LottieAvatar.tsx
import React from "react";

import animationData from "../../../../accset/json/avatarAdmin.json"; // Path to your Lottie file
import Lottie from "lottie-react";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";

const AvatarAdmin: React.FC<{ size: number }> = ({ size }) => {
  const { t } = useTranslation();
  const content = (
    <p style={{ padding: "5px 8px", color: `var(--primary-color)` }}>
      {t("needHelp")}
    </p>
  );
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: size, height: "100%" }}>
        <Popover placement="top" content={content}>
          <Lottie animationData={animationData} />
        </Popover>
      </div>
    </div>
  );
};

export default AvatarAdmin;
