import React, { useState } from "react";
import { Button, Col, Row, Form, Input, Flex } from "antd";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import Loading from "../../global/item/Loading/loading";
import authService from "../../service/auth";
import UseRespon from "../../../hook/useResponsive";
import signInAnimation from "../../../accset/json/signInJson.json";

import "./SignIn.scss";
import { openNotification } from "../../global/item/Notification/Notification";
import { Link, useNavigate } from "react-router-dom";
import Translation from "../../global/item/Translation/Translation";

function RegisterApp() {
  const { t } = useTranslation();
  const { isMobile } = UseRespon();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    const data = {
      username: values.username,
      email: values.email,
      password: values.password,
      authMethod: "email",
    };

    try {
      setLoading(true);
      await authService.register(data);
      openNotification({
        type: "success",
        description: t("otpSentToEmail"),
      });
      navigate("/sign-in");
    } catch (error) {
      openNotification({
        type: "error",
        message: t("emailExists"),
        description: t("useAnotherEmail"),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div style={{ background: "#f2f2f2" }}>
          <Row style={{ height: "100vh" }}>
            {isMobile ? null : (
              <Col xl={16}>
                <div
                  style={{
                    height: "50px",
                    borderRadius: "10px",
                    margin: "10px 40px",
                  }}
                >
                  <Flex>
                      <h2
                        style={{
                          color: `var(--primary-color)`,
                          fontFamily: "Playwrite CU, sans-serif",
                        }}
                      >
                        E<span style={{ color: "#000" }}>learning</span>
                      </h2>
                    </Flex>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "calc(100vh - 90px)",
                  }}
                >
                  <Lottie
                    animationData={signInAnimation}
                    style={{ width: 550 }}
                  />
                </div>
              </Col>
            )}
            <Col xl={8} xs={24} className="signInRight">
              <Translation />
              <h2 className="signInRight--title">{t("welcome")}</h2>
              <p className="signInRight--desc">{t("signUpAccount")}</p>

              <Form
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  className="formItem"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: t("usernameRequired"),
                    },
                  ]}
                >
                  <div className="form-field">
                    <Input className="form-input" placeholder="" />
                    <label htmlFor="username" className="form-label">
                      {t("username")}
                    </label>
                  </div>
                </Form.Item>
                <Form.Item
                  className="formItem"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t("emailRequired"),
                    },
                    {
                      type: "email",
                      message: t("emailGmailRequired"),
                    },
                  ]}
                >
                  <div className="form-field">
                    <Input className="form-input" placeholder="" />
                    <label htmlFor="email" className="form-label">
                      {t("email")}
                    </label>
                  </div>
                </Form.Item>

                <Form.Item
                  name="password"
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: t("passwordRequired"),
                    },
                  ]}
                >
                  <div className="form-field">
                    <Input
                      className="form-input"
                      type="password"
                      placeholder=" "
                    />
                    <label htmlFor="password" className="form-label">
                      {t("password")}
                    </label>
                  </div>
                </Form.Item>

                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    height: 46,
                    marginTop: 20,
                    fontWeight: 600,
                  }}
                >
                  {t("signUp")}
                </Button>
                <p style={{ margin: "10px", display: "flex" }}>
                  {t("useAccount")}
                  <Link
                    to="/sign-in"
                    style={{ color: `var(--primary-color)`, paddingLeft: 5 }}
                  >
                    {t("loginNow")}
                  </Link>
                </p>
              </Form>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default RegisterApp;
