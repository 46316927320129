import {  Flex, Modal, Radio, Select } from "antd";
import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import animation from "../../../accset/json/wave.json"

function GrammarAnswer() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setIsModalVisible(true);
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Modal
        title="Thiết lập bài kiểm tra"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
      >
        <Flex style={{ margin: "20px 0 20px 0" }} vertical>
          <div>
            <h3 style={{ marginBottom: 8 }}>Chọn chủ đề</h3>
            <Select
              mode="multiple"
              placeholder="Chọn chủ đề"
              className="selectForm"
              style={{ width: "100%" }}
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "Yiminghe", label: "Yiminghe" },
              ]}
            />
          </div>
          <div>
            <h3 style={{ color: `var(--primary-color)`, marginBottom: 8 }}>
              Chọn từ loại
            </h3>
            <Select
              mode="multiple"
              placeholder="Chọn chủ đề"
              className="selectForm"
              style={{ width: "100%" }}
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "Yiminghe", label: "Yiminghe" },
              ]}
            />
          </div>
          <div>
            <h3 style={{ color: `var(--primary-color)`, marginBottom: 8 }}>
              Số lượng từ
            </h3>
            <Radio.Group defaultValue="a" style={{ width: "100%" }}>
              <Radio.Button value="a">Hangzhou</Radio.Button>
              <Radio.Button value="b">Shanghai</Radio.Button>
              <Radio.Button value="c">Beijing</Radio.Button>
              <Radio.Button value="d">Chengdu</Radio.Button>
            </Radio.Group>
          </div>
        </Flex>
      </Modal>
      <Lottie animationData={animation} style={{ width:400,height:"90vh", margin:"0 auto"}} />

    </div>
  );
}

export default GrammarAnswer;
